import { useEffect, useState } from 'react';

interface CampaignInfo {
  campaignCode: string | null;
  startDate: string;
}

export function findActiveCampaign<T extends CampaignInfo>(campaigns: T[], date: Date) {
  let activeCampaign: T | null = null;

  for (const info of campaigns) {
    const startDate = new Date(info.startDate);
    if (startDate > date) {
      continue;
    }
    if (activeCampaign && new Date(activeCampaign.startDate) > new Date(info.startDate)) {
      continue;
    }
    activeCampaign = info;
  }
  return activeCampaign;
}

export default function useCampaign<T extends CampaignInfo>(data: T[], today = new Date()) {
  const [campaign, setCampaign] = useState<T | null>(null);

  useEffect(() => {
    setCampaign(findActiveCampaign(data, today));
  }, [data]);

  return campaign;
}
