import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import { Section, Stack, Inline } from '@nib/layout';
import Copy from '@nib-components/copy';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import HeroPanel from '@nib-components/hero-panel';
import { colorTrueGreen, linkLoud } from '@nib-components/theme';

import Layout from '../components/Layout';
import metrics from '../metrics';
import RangeOfPlans from '../components/shared/RangeOfPlans';
import WhyChooseNib from '../components/shared/WhyChooseNib';
import UtilityButtons from '../components/UtilityButtons';
import CallBackModal from '../components/CallBackModal';
import { urlConstants } from '../constructs/constants';
import { addColorStyleToAnchorTags } from '../utils/html-string';
import useCampaign from '../hooks/useCampaign';

export const specialOffersPageQuery = graphql`
  query SpecialOffersPageQuery {
    allContentfulSpecialOfferPage(sort: { fields: startDate, order: DESC }, limit: 2) {
      edges {
        node {
          title
          heroTitle
          startDate
          campaignCode
          legal {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          heroCopy {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          heroImage {
            desktop {
              file {
                url
              }
            }
            desktop2x4000x1200 {
              file {
                url
              }
            }
            mobile2x800x300 {
              file {
                url
              }
            }
            mobile400x150 {
              file {
                url
              }
            }
            tablet2x1600x580 {
              file {
                url
              }
            }
            tablet800x290 {
              file {
                url
              }
            }
          }
          utilityButtons {
            title
            subtitle
            url
          }
          importantThingsToKnow {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

interface ContentSectionProps {
  html: string;
  className?: string;
}

const StyledCopy = styled.div`
  a {
    color: ${linkLoud};
  }
}`;

const ContentSection = (props: ContentSectionProps): JSX.Element => {
  const { html } = props;
  return (
    <Container className={props.className}>
      <Copy measure={false}>
        <StyledCopy dangerouslySetInnerHTML={{ __html: html }} />
      </Copy>
    </Container>
  );
};

type HeaderSectionProps = {
  title: string;
  copy: RawAndHtmlMarkdownShape;
  images: HeroImagesShape;
  legalCopy: RawAndHtmlMarkdownShape;
};

const HeaderSection = ({ title, copy, images, legalCopy }: HeaderSectionProps): JSX.Element => (
  <HeroPanel
    title={title}
    variation="condensed"
    images={{
      desktop: images?.desktop?.file?.url,
      desktop2x: images?.desktop2x4000x1200?.file?.url,
      mobile: images?.mobile400x150?.file?.url,
      mobile2x: images?.mobile2x800x300?.file?.url,
      tablet: images?.tablet800x290?.file?.url,
      tablet2x: images?.tablet2x1600x580?.file?.url,
    }}
  >
    <ContentSection html={copy?.childMarkdownRemark?.html} />
    <Inline space={{ xs: 2, md: 4 }}>
      <SecondaryButton href="/compare-plans">Compare plans</SecondaryButton>
      <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
    </Inline>
    <Copy>
      <StyledCopy dangerouslySetInnerHTML={{ __html: legalCopy?.childMarkdownRemark?.html }} />
    </Copy>
  </HeroPanel>
);

const Section3 = (): JSX.Element => {
  return (
    <Container>
      <Stack space={{ xs: 4, md: 6 }}>
        <a id="call-back" />
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Have questions? We can help.
        </Heading>
        <Copy measure={false}>
          Still need help deciding what plan is right for you? Give us a call. Choose a time and one
          of our friendly consultants will call you back, normally within one working day (Monday -
          Friday 8:00am to 5:30pm). Or, speak with a consultant now – 0800 123 642, option 4.
        </Copy>
        <CallBackModal variant="Expert" isModal={false} />
      </Stack>
    </Container>
  );
};

const Section4 = ({ content }: { content: HtmlMarkdownShape }): JSX.Element => {
  const ContentSectionStyled = styled(ContentSection)`
    word-break: break-word;
    h2 {
      color: ${colorTrueGreen};
      font-family: 'Buenos Aires', 'Georgia', serif;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 1.333;

      @media (min-width: 800px) {
        font-size: 2.25rem;
      }
    }
    h4 {
      color: ${colorTrueGreen};
      font-size: 1.25rem;
    }
    ul > li {
      margin-bottom: 1rem;
    }
  `;
  return (
    <Container id="important-things-to-know">
      <Stack space={{ xs: 4, md: 6 }}>
        <ContentSectionStyled
          html={addColorStyleToAnchorTags(content?.childMarkdownRemark?.html)}
        />
      </Stack>
    </Container>
  );
};

const CallbackFormAndThingsToKnowSection = ({
  content,
}: {
  content: HtmlMarkdownShape;
}): JSX.Element => {
  return (
    <Section>
      <Stack space={7}>
        <Section3 />
        <Section4 content={content} />
      </Stack>
    </Section>
  );
};

const UtilityButtonsSection = ({ buttons }): JSX.Element => {
  return (
    <Section background="warmWhite60">
      <UtilityButtons buttons={buttons} flex />
    </Section>
  );
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface UtilityButtonsShape {
  title: string;
  subtitle: string;
  url: string;
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  utilityButtons: UtilityButtonsShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

interface SpecialOffersProps {
  data: {
    allContentfulSpecialOfferPage: {
      edges: {
        node: NodeShape;
      }[];
    };
  };
}

const title = 'Special offers';

const SpecialOffers = (props: SpecialOffersProps): JSX.Element => {
  const data = props.data.allContentfulSpecialOfferPage.edges;
  const description = data.find(function (e) {
    return e.node[0];
  });

  const campaignInfo = useCampaign(data.map((d) => d.node));

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta
          name="description"
          content={description?.node.heroCopy.childMarkdownRemark.rawMarkdownBody}
        />
      </Helmet>
      <div>
        {campaignInfo ? (
          <HeaderSection
            title={campaignInfo.heroTitle}
            copy={campaignInfo.heroCopy}
            images={campaignInfo.heroImage}
            legalCopy={campaignInfo.legal}
          />
        ) : (
          <div style={{ minHeight: 500 }}></div>
        )}
      </div>
      <WhyChooseNib left="MoreThanHealthCover" middle="YourChoice" right="EligibleCosts" />
      <RangeOfPlans />
      {campaignInfo && (
        <>
          <CallbackFormAndThingsToKnowSection content={campaignInfo.importantThingsToKnow} />
          <UtilityButtonsSection buttons={campaignInfo.utilityButtons} />
        </>
      )}
    </Layout>
  );
};

export default metrics({ pageName: 'special-offers' })(SpecialOffers);
