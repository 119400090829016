import React from 'react';

import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import Card from '@nib/card';
import { Section, Stack, Tiles } from '@nib/layout';
import { HealthInsuranceGraphicIcon, DoctorGraphicIcon, HospitalGraphicIcon } from '@nib/icons';
import { ReducedGapCard } from '../styledComponents';

const HospitalPlanTile = (): JSX.Element => {
  return (
    <ReducedGapCard padding={3} height="100%" icon={HospitalGraphicIcon} align="center">
      <Card.Content title="Hospital plan">
        <Copy>
          Cover to help pay for the big things like specialists, surgery, cancer treatment and
          hospital visits.
        </Copy>
      </Card.Content>
    </ReducedGapCard>
  );
};

const EverydayPlanTile = (): JSX.Element => {
  return (
    <ReducedGapCard padding={3} height="100%" icon={DoctorGraphicIcon} align="center">
      <Card.Content title="Everyday plan">
        <Copy>
          Cover for your day-to-day health costs like dentist, GP, and physio appointments and your
          prescriptions.
        </Copy>
      </Card.Content>
    </ReducedGapCard>
  );
};

const CombinedPlanTile = (): JSX.Element => {
  return (
    <ReducedGapCard padding={3} height="100%" icon={HealthInsuranceGraphicIcon} align="center">
      <Card.Content title="Combined plans">
        <Copy>Cover that gives you the features from both Hospital and Everyday plans.</Copy>
      </Card.Content>
    </ReducedGapCard>
  );
};

const RangeOfPlans = (): JSX.Element => {
  return (
    <Section background="trueGreen">
      <Stack space={4}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" align="left" color="white">
          Our range of plans
        </Heading>
        <Copy color="white" measure={false}>
          Easy to use, easy to claim on and offering more than just great cover, our plans help put
          you in control of your health journey.
        </Copy>
        <Tiles space={{ xs: 2, xl: 4 }} columns={{ sm: 1, md: 2, lg: 3 }}>
          <HospitalPlanTile />
          <EverydayPlanTile />
          <CombinedPlanTile />
        </Tiles>
      </Stack>
    </Section>
  );
};

export default RangeOfPlans;
